@media only screen and (max-width: 250px) {
  .training-btn {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .training-center {
    display: inline-block;  
    width: 960px;
    text-align: left;
  }
}

@media only screen and (max-width: 1000px) {
  .training-center {
    display: inline-block;  
    width: 95%;
    text-align: left;
  }
}

.speedcontrolcontainer {
  max-width: 30em;
  display: block;
  /*border: 1px solid #000;*/
  /*padding: 10px;*/
  font-family: Sans-serif;
}
.speedcontrolcontainer audio {
  width: 100%;
  display: block;
}
.speedcontrolcontainer div {
  display: flex;
  /*padding: .5em 0;*/
  /*gap: 5px;*/
}
.speedcontrolcontainer label {
  flex: 1
}
.speedcontrolcontainer input[type="range"] {
  flex: 5
}
.speedcontrolcontainer span {
  flex: 1;
  text-align: center;
}
