@media only screen and (min-width: 1000px) {
  .profiles-center {
    display: inline-block;  
    width: 960px;
    text-align: left;
  }
}

@media only screen and (max-width: 1000px) {
  .profiles-center {
    display: inline-block;  
    width: 95%;
    text-align: left;
  }

  .breaking {
  	width: 95%;
  }
}